<template>
  <div>
    <iq-card v-if="!post.is_hidden" body-class="p-0" class="peep-post mb-0">
      <template v-slot:body>
        <div class="user-post-data p-3 position-relative">
          <div class="d-flex justify-content-between">
            <PostUserInfo
              :post="post"
              :user="post.user"
              :user-popover-component="userPopover"
              :notification-button-component="notificationButton"
            />
            <div class="d-flex peep-actions-absolute">
              <PeepActions
                :post="post"
              />
            </div>
          </div>
        </div>
      </template>
      <!-- <hr class="m-0" /> -->
      <component
        v-bind:is="postContent"
        :post="post"
      />
      <FeedPostComments :hideComments="hideComments" fake-news repeep :post="post" />
    </iq-card>
    <iq-card v-else body-class="p-0" class="peep-post">
      <template v-slot:body>
        <div class="d-flex justify-content-between align-items-center m-2">
          <div class="text-bold">Tweet hidden</div>
          <b-button variant="facebook" @click="hidePost">Undo</b-button>
        </div>
      </template>
    </iq-card>
  </div>
</template>
<script>
import * as postTypes from '@/config/post/postType'
import PeepNotificationButton from '@/components/homeiz/feed/post/PeepNotificationButton'
import PeepUserPopover from '@/components/homeiz/user/UserPopoverPeep'
import PostContent from '@/components/homeiz/feed/post/type/PostContent'
import PollContent from '@/components/homeiz/feed/post/type/PollContent'
import PropertyContent from '@/components/homeiz/feed/post/type/PropertyContent'
import FeedPostComments from '@/components/homeiz/feed/FeedPostComments'
import PostUserInfo from '@/components/homeiz/feed/post/PostUserInfo'
import PeepActions from '@/components/homeiz/feed/post/PeepActions'

export default {
  name: 'PeepPost',
  components: { FeedPostComments, PostUserInfo, PeepActions },
  props: {
    post: {
      type: Object,
      required: true
    },
    hideComments: {
      type: Boolean,
      default: false
    }
  },
  mounted () { },
  data () {
    return {
      notificationButton: PeepNotificationButton,
      userPopover: PeepUserPopover
    }
  },
  computed: {
    postContent () {
      const typeMap = {
        [postTypes.POST]: PostContent,
        [postTypes.POLL]: PollContent,
        [postTypes.PROPERTY]: PropertyContent
      }

      return typeMap[this.post.type]
    }
  },
  methods: {
    hidePost () {
      this.post.is_hidden = !this.post.is_hidden
    }
  }
}
</script>
