<template>
    <ChatBar :users="users" />
</template>

<script>
import { mapGetters } from 'vuex'
import ChatBar from '../../../components/homeiz/rightSidebar/ChatBar'
import * as getters from '@/store/modules/chat-panel/types/getters'

export default {
  name: 'PeepChat',
  components: { ChatBar },
  computed: {
    ...mapGetters('chatPanel', {
      users: getters.GET_BUSINESS_USERS
    })
  },
  data () {
    return {
      title: 'Tweet'
    }
  }
}
</script>

<style scoped>

</style>
