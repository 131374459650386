<template>
  <div class="d-flex">
    <div class="iq-card-header-toolbar d-flex align-items-start">
      <b-dropdown id="dropdownMenuButton40" right variant="erez007" toggle-class="pl-0 post-action-btn" menu-class="p-0">
        <template v-slot:button-content>
          <b-link href="javascript:void(0)" class="text-secondary text-center">
            <i class="m-0 ri-more-line"></i></b-link>
        </template>
        <div class="addon-for-window">
          <svg aria-hidden="true" height="12" viewBox="0 0 21 12" width="21" class="x10l6tqk xng853d xds687c" fill="var(--card-background)" style="transform: scale(-1, -1) translate(0px);"><path d="M20.685.12c-2.229.424-4.278 1.914-6.181 3.403L5.4 10.94c-2.026 2.291-5.434.62-5.4-2.648V.12h20.684z"></path></svg>
        </div>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-user-follow-line"></i></div>
            <div class="data ml-2">
              <h6>Follow @{{ post.user.username }}</h6>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-user-unfollow-line"></i></div>
            <div class="data ml-2">
              <h6>Block @{{ post.user.username }}</h6>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-flag-2-line"></i></div>
            <div class="data ml-2">
              <h6>Report Tweet</h6>
            </div>
          </div>
        </a>
        <a href="javascript:void(0)" class="dropdown-item p-2">
          <div class="d-flex align-items-center">
            <div class="icon font-size-20"><i class="ri-delete-bin-line"></i></div>
            <div class="data ml-2">
              <h6>Delete this Tweet</h6>
            </div>
          </div>
        </a>
      </b-dropdown>
    </div>
    <div class="position-relative d-flex align-items-start justify-content-center">
      <b-button class="cursor-pointer hide-post ml-0" title="Hide" @click="hidePost">
        <i class="ri-close-line mr-0"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostActions',
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  methods: {
    hidePost () {
      this.post.is_hidden = !this.post.is_hidden
    }
  }
}
</script>

<style>
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--iq-primary) !important;
}

.dropdown-menu {
  border-top-right-radius: 0px !important;
  left: -9px !important;
}

.addon-for-window {
  position: absolute;
  top: -18px;
  right: 0px;
  fill: rgb(255, 255, 255);
}
</style>
