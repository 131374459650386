<template>
  <div>
    <div to="rightSidebar">
      <PeepChat/>
    </div>
    <b-row>
      <b-col sm="12">
        <b-row class=" m-0 p-0">
          <b-col lg="8">
            <div class="col-sm-12 peep-feed">
              <iq-card  body-class="p-0" class="bookmark-header" :class="items.length ? 'border-b-none' : '' ">
                <template v-slot:body>
                  <div class="text-center">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex">
                        <b-button variant="erez007" :to="{ name: 'social.feed.peep' }"><i class="ri-arrow-left-line m-0 text-black"></i></b-button>
                        <h4 class="ml-2 text-left">Bookmarks</h4>
                      </div>
                      <div class="d-flex align-items-start justify-content-center align-items-center">
                        <b-button @click="clearBookmarksHandler" v-if="items.length" variant="text" class="cursor-pointer" title="Clear all Bookmarks">
                          Clear all Bookmarks
                        </b-button>
                        <div v-if="items.length" class="iq-card-header-toolbar d-flex justify-content-end mr-4">
                          <b-dropdown id="dropdownMenuButton40" right variant="erez007" toggle-class="pl-0 post-action-btn" menu-class="p-0">
                            <template v-slot:button-content>
                              <b-link href="javascript:void(0)">
                                <i class="m-0 ri-more-line font-size-16"></i>
                              </b-link>
                            </template>
                            <a href="javascript:void(0)" class="dropdown-item p-2">
                              <div class="d-flex align-items-center">
                                <div class="icon font-size-20">
                                  <i class="ri-close-circle-line"></i>
                                </div>
                                <div class="data ml-2">
                                  <div>Something will be here</div>
                                </div>
                              </div>
                            </a>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                    <div class="user-post-data d-flex justify-content-between p-3">
                      <div class="user-img d-flex align-items-center">
                        <img :src="authUser.avatar" :alt="fullName" class="avatar-40 rounded-circle img-fluid">
                        <h5 class="ml-3 text-left">
                          <!-- <div>{{ fullName }}</div> -->
                          <div>@{{ authUser.username }}</div>
                        </h5>
                      </div>
                    </div>
                  </div>
                </template>
                <hr class="m-0"/>
                <div class="user-post text-center" v-if="!items.length">
                  <h3>Save posts for later</h3>
                  <p class="p-2 pl-4 pr-4">
                    Bookmark posts that will allow you quick access to that posts instead of having to search for them.
                  </p>
                </div>
              </iq-card>
              <div v-for="post in items" :key="post.id">
                <div class="d-flex align-items-center w-100">
                  <div class="mr-2 text-danger">
                    <b-button variant="text" class="cursor-pointer" title="Delete bookmark">
                      <i class="text-danger ri-delete-bin-line"></i>
                    </b-button>
                  </div>
                  <div class="w-100">
                    <SocialPost hide-comments :post="post"></SocialPost>
                  </div>
                </div>
              </div>
              <div v-if="items.length" class="d-flex justify-content-center">
                <button class="btn btn-primary mb-3" type="button">Load more</button>
              </div>
            </div>
          </b-col>
          <b-col lg="4" >
            <FollowWidget class="peep trends-widget"/>
            <hr/>
            <TrendsWidget class="peep trends-widget"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as feedGetters from '@/store/modules/feed/types/getters'
import * as feedActions from '@/store/modules/feed/types/actions'
import { socialvue } from '@/config/pluginInit'
import SocialPost from '@/components/homeiz/feed/PeepPost'
import PeepChat from '@/components/homeiz/chatBars/PeepChat'
import TrendsWidget from '@/components/homeiz/widgets/TrendsWidget'
import FollowWidget from '@/components/homeiz/widgets/FollowWidget'

export default {
  name: 'PeepBookmarks',
  components: {
    SocialPost,
    PeepChat,
    TrendsWidget,
    FollowWidget
  },
  inject: ['authUser'],
  created () { },
  async mounted () {
    socialvue.index()
    socialvue.bodyClassCheck('', 'peep')
    this.loaded = true
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters('feed', {
      bookmarks: feedGetters.GET_PEEP_BOOKMARKS,
      bookmarksMap: feedGetters.GET_PEEP_BOOKMARKS_MAP
    }),
    items () {
      return this.bookmarksMap.map(id => this.bookmarks[id])
    },
    fullName () {
      return `${this.authUser.firstName} ${this.authUser.lastName}`
    }
  },
  methods: {
    ...mapActions('feed', {
      clearBookmarks: feedActions.CLEAR_PEEP_BOOKMARKS
    }),
    clearBookmarksHandler () {
      this.clearBookmarks()
    }
  }
}
</script>
<style lang="scss">
  .bookmark-header {
    margin-left: 54px;
    margin-bottom: 0 !important;
    &.border-b-none {
      border-bottom: none !important;
    }
  }

</style>
