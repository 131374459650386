<template>
    <iq-card headerClass="title-w-100">
        <template v-slot:headerTitle>
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="card-title">What's trending</h6>
            <router-link :to="{}" class="btn btn-text text-black d-block font-10-pt">See All</router-link>
          </div>
        </template>
        <template v-slot:body>
            <div v-for="(item,index) in trends" :key="index" class="d-flex align-items-center justify-content-between">
                <div class="d-inline-block">
                    <h5>
                        <a href="#" class="trend font-10-pt">{{ item.title }}</a>
                    </h5>
                    <h6 class="text-body font-9-pt text-bold font-color-rgb-140">{{ item.count }} followers</h6>
                </div>
                <b-button variant="text" class="w-30 font-10-pt">Follow</b-button>
            </div>
        </template>
    </iq-card>
</template>

<script>
export default {
  name: 'TrendsWidget',
  data () {
    return {
      trends: [
        { title: '#realestate', count: 1023 },
        { title: '#business ', count: 789 },
        { title: '#guitar ', count: 689 },
        { title: '#music', count: 555 },
        { title: '#it', count: 220 }
      ]
    }
  }
}
</script>

<style scoped>

</style>
