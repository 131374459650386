<template>
    <iq-card headerClass="title-w-100">
        <template v-slot:headerTitle>
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="card-title">Suggested for you</h6>
            <router-link :to="{}" class="btn btn-text text-black font-10-pt d-block">See All</router-link>
          </div>
        </template>
        <template v-slot:body>
            <div v-for="(item,index) in usersLimited" :key="index" class="d-flex align-items-center justify-content-between">
                <div class="d-inline-block">
                  <div class="media-height iq-chat-data-block active-block">
                        <div class="media cursor-pointer align-items-center mb-2" :id="`popover-follow-user-${item.id}`">
                            <div class="iq-profile-avatar status-online">
                                <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
                            </div>
                            <div class="media-body ml-3">
                                <h6 class="mb-0 font-10-pt text-bold">{{item.fullName}}</h6>
                                <p class="mb-0 font-9-pt text-bold font-color-rgb-140">{{item.time}}</p>
                            </div>
                          <b-popover custom-class="user-info-popover" :target="`popover-follow-user-${item.id}`" placement="bottom" triggers="hover focus">
                            <UserPopover :user="item"/>
                          </b-popover>
                        </div>
                    </div>
                </div>
                <b-button variant="text" class="w-30 font-10-pt">Follow</b-button>
            </div>
        </template>
    </iq-card>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/modules/chat-panel/types/getters'
import UserPopover from '@/components/homeiz/user/UserPopoverPeep'

export default {
  name: 'FollowWidget',
  components: {
    UserPopover
  },
  data () {
    return {
      limit: 6
    }
  },
  computed: {
    ...mapGetters('chatPanel', {
      users: getters.GET_BUSINESS_USERS
    }),
    usersLimited () {
      return Object.entries(this.users).slice(0, 6).map(entry => entry[1])
    }
  },
  methods: {
    renderIcon (icon) {
      return require(`@/assets/images${icon}`)
    }
  }
}
</script>

<style scoped>

</style>
