<template>
  <NotificationButton
    class="pb-1 ml-1"
    icon="/icon/bell-black-icon.svg"
    icon-active="/icon/bell-red-icon.svg"
    :absolute="false"
    :tooltip="`Get notified about all of ${name}'s tweets.`"
    :tooltip-unsubscribe="`Unsubscribe to ${name}'s tweets`"
    :subscribe="`You had subscribed to the ${name}'s tweets.`"
    :unsubscribe="`You had unsubscribed to the ${name}'s tweets.`"
  />
</template>

<script>
import NotificationButton from '@/components/homeiz/notifications/NotificationButton'

export default {
  name: 'PostNotificationButton',
  components: { NotificationButton },
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>
